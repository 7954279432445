<template>
  <div id="phoneBook">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li>
        <router-link :to="{name: 'Notifications'}">Obvestila za uporabnike</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">
        <span v-if="$route.params.notificationId">Urejanje obvestila</span>
        <span v-if="!$route.params.notificationId">Dodajanje obbvestila</span>
      </li>
    </vs-breadcrumb>


    <div class="sm:none md:flex-auto lg:flex xl:flex">
      <div class="sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
        <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

        <div class="grid grid-cols-1 mb-10">
          <div>
            <h1>
              <span v-if="$route.params.notificationId">Urejanje obvestila</span>
              <span v-if="!$route.params.notificationId">Dodajanje obvestila</span>
            </h1>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <vs-input class="w-full" label="Naslov sporočila" type="text"
                      v-model="entry.title"/>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <label class="vs-input--label">Povzetek vsebine</label>
            <vs-textarea class="w-full" rows="3" v-model="entry.body"/>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <label class="vs-input--label">Vsebina</label>
            <vue-editor v-model="entry.formattedBody"></vue-editor>
          </div>
        </div>

        <div class="vx-card mt-10">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Dogodek</h4>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div class="mb-10">

                <div class="grid grid-cols-1">
                  <table class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                    <tr>
                      <th style="width: 40px;" class="text-center">#</th>
                      <th>Naziv</th>
                      <th>Lokacija</th>
                      <th>Čas</th>
                      <th>Akcije</th>
                    </tr>
                    </thead>
                    <tbody class="vs-table--tbody">
                    <tr class="tr-values" v-for="(eventItem,index) in entry.eventDetails" :key="'eventItem_' + index"
                        v-if="entry && entry.eventDetails && typeof entry.eventDetails === 'object'">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>{{ eventItem.eventTitle }}</td>
                      <td>{{ eventItem.reservationData.toAddress.addressName }}</td>
                      <td>{{ moment(eventItem.eventStartTime).format('DD. MM. YYYY') }}, {{
                          moment(eventItem.eventStartTime).format('HH:mm') + ' - ' + moment(eventItem.eventEndTime).format('HH:mm')
                        }}
                      </td>
                      <td>
                        <div class="vs-col vs-xs- vs-sm- vs-lg-"
                             style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                          <vs-button size="small" color="primary" type="border" icon="edit"
                                     @click="editEvent(eventItem, index)"
                                     class="my-5 mx-2">Uredi
                          </vs-button>
                          <vs-button size="small" color="danger" type="border" icon="remove"
                                     @click="deleteEvent(eventItem, index)"
                                     class="my-5 mx-2">Izbriši
                          </vs-button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <vs-button size="small" color="success" type="border" icon="add" @click="showAddEventModal()" class=""
                         v-if="entry.eventDetails.length === 0">
                Dodaj
                dogodek
              </vs-button>

            </div>
          </div>
        </div>


        <div class="vx-card mt-10">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Povezave</h4>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <div class="mb-10">

                <div class="grid grid-cols-1">
                  <table class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                    <tr>
                      <th style="width: 40px;" class="text-center">#</th>
                      <th>Opis</th>
                      <th>Povezava</th>
                      <th>Akcije</th>
                    </tr>
                    </thead>
                    <tbody class="vs-table--tbody">
                    <tr class="tr-values" v-for="(linkItem,index) in entry.linkDetails" :key="'linkItem_' + index"
                        v-if="entry && entry.linkDetails && typeof entry.linkDetails === 'object'">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>{{ linkItem.label }}</td>
                      <td>{{ linkItem.link }}</td>
                      <td>
                        <div class="vs-col vs-xs- vs-sm- vs-lg-"
                             style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;">
                          <vs-button size="small" color="primary" type="border" icon="edit"
                                     @click="editLink(linkItem, index)"
                                     class="my-5 mx-2">Uredi
                          </vs-button>
                          <vs-button size="small" color="danger" type="border" icon="remove"
                                     @click="deleteLink(linkItem, index)"
                                     class="my-5 mx-2">Izbriši
                          </vs-button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <vs-button size="small" color="success" type="border" icon="add" @click="showAddLink()" class="">
                Dodaj
                povezavo
              </vs-button>

            </div>
          </div>
        </div>


        <div class="flex mt-10 mb-20 mt-20">
          <div class="w-1/2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
            </vs-col>
          </div>
          <div class="w-1/2">
            <div class="flex">
<!--              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="entry.id">-->
<!--                <vs-button size="large" color="danger" @click="beforeDeletePhoneEntry" icon="delete">Izbriši</vs-button>-->
<!--              </vs-col>-->

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="success" icon="save" v-if="$route.params.notificationId" @click="updatePushNotification()">Shrani spremembe</vs-button>
                <vs-button size="large" color="success" icon="save" v-if="!$route.params.notificationId" @click="createPushNotification()">Shrani</vs-button>
              </vs-col>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div class="sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
        <template>


          <div class="vx-card mt-10" style="overflow: hidden">
            <div class="vx-card__header">
              <h4 class="text-success mb-4">Slika</h4>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body">

                <div class="w-full flex items-center">
                  <img v-if="entry.image" size="1170px"
                             :src="$globalFunctions.getImage(entry.image)" class="ml-auto mr-auto"/>
                </div>

                <div class="w-full flex items-center mt-3">
                  <input type="file" ref="file" @change="fileChanged" accept="image/jpeg, image/png, image/gif"
                         class="ml-auto mr-auto"/>
                </div>

              </div>
            </div>
          </div>

        </template>
      </div>

    </div>


    <vs-popup :title="currentLinkId !== null ? 'Urejanje povezave' : 'Dodajanje povezave'"
              :active.sync="editLinkModal">
      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-input class="w-full" label="Opis" type="text"
                    v-model="currentLinkLabel"/>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-input class="w-full" label="Povezava" type="text"
                    v-model="currentLinkURL"/>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-button size="medium" color="success" icon="check" @click="addLink()">
            <span v-if="currentLinkId !== null">Shrani spremembe</span>
            <span v-if="currentLinkId === null">Dodaj povezavo</span>
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup :title="currentEventId !== null ? 'Urejanje dogodka' : 'Dodajanje dogodka'"
              :active.sync="editEventModal">

      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-input class="w-full" label="Naziv" type="text"
                    v-model="currentEventTitle"/>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>
          <label class="vs-input--label">Začetek dogodka</label>
          <date-picker class="w-full" input-class="vs-inputx vs-input--input normal hasValue"
                       v-model="currentEventStartTime" :type="'datetime'"
                       :format="'DD. MM. YYYY, HH:mm'" :append-to-body="false" :minute-step="15"
                       :value-type="'timestamp'"></date-picker>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>
          <label class="vs-input--label">Konec dogodka</label>
          <date-picker class="w-full" input-class="vs-inputx vs-input--input normal hasValue"
                       v-model="currentEventEndTime" :type="'datetime'"
                       :format="'DD. MM. YYYY, HH:mm'" :append-to-body="false" :minute-step="15"
                       :value-type="'timestamp'"></date-picker>
        </div>
      </div>

      <div class="grid grid-cols-1 mt-5">
        <div>

          <div class="grid grid-cols-1">
            <label for="customersAddress" class="vs-input--label">Naslov</label>

            <gmap-autocomplete
              ref="eventAddress"
              id="eventAddress"
              @place_changed="(data) => { getAddressData(data, 'event') }"
              placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
              :value="currentEventAddress.toAddress.quick_search"
              :options="{componentRestrictions: { country: 'si' }}"
              :select-first-on-enter="true"
              class="vs-inputx vs-input--input normal"></gmap-autocomplete>
          </div>

          <!--          <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.quick_search.$dirty">-->
          <!--            <div class="w-full">-->
          <!--              <vs-alert :active="!$v.currentEventAddress.toAddress.quick_search.required" :color="'danger'">-->
          <!--                Polje "Naslov" ne sme biti prazno.-->
          <!--              </vs-alert>-->
          <!--            </div>-->
          <!--          </div>-->


          <div class="flex mt-5">
            <div class="w-full">
              <vs-input class="w-full" label="Naziv naslova (ime naselja, vasi oz. ustanove)"
                        v-model="currentEventAddress.toAddress.addressName"/>
            </div>
          </div>


          <div slot="header" class="p-0 text-primary mt-5">
            Podrobnosti naslova
          </div>

          <div class="collapseBody mt-5">

            <div class="flex mb-4">
              <div class="w-3/4">
                <vs-input class="w-full" label="Ulica" v-model="currentEventAddress.toAddress.line1"/>

                <!--                <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.line1.$dirty">-->
                <!--                  <div class="w-full">-->
                <!--                    <vs-alert :active="!$v.currentEventAddress.toAddress.line1.required" :color="'danger'">-->
                <!--                      Polje "Ulica" ne sme biti prazno.-->
                <!--                    </vs-alert>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="w-1/4 ml-5">
                <vs-input class="w-full" label="Hišna številka"
                          v-model="currentEventAddress.toAddress.line2"/>

                <!--                <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.line2.$dirty">-->
                <!--                  <div class="w-full">-->
                <!--                    <vs-alert :active="!$v.currentEventAddress.toAddress.line2.required" :color="'danger'">-->
                <!--                      Polje "Hišna številka" ne sme biti prazno.-->
                <!--                    </vs-alert>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="flex mb-4">
              <div class="w-3/4">
                <vs-input class="w-full" label="Naziv pošte" v-model="currentEventAddress.toAddress.city"/>

                <!--                <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.city.$dirty">-->
                <!--                  <div class="w-full">-->
                <!--                    <vs-alert :active="!$v.currentEventAddress.toAddress.city.required" :color="'danger'">-->
                <!--                      Polje "Naziv pošte" ne sme biti prazno.-->
                <!--                    </vs-alert>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="w-1/4 ml-5">
                <vs-input class="w-full" label="Poštna številka"
                          v-model="currentEventAddress.toAddress.postal_code"/>

                <!--                <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.postal_code.$dirty">-->
                <!--                  <div class="w-full">-->
                <!--                    <vs-alert :active="!$v.currentEventAddress.toAddress.postal_code.required" :color="'danger'">-->
                <!--                      Polje "Poštna številka" ne sme biti prazno.-->
                <!--                    </vs-alert>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="flex mb-4">
              <div class="w-3/4">
                <vs-input class="w-full" label="Kraj" v-model="currentEventAddress.toAddress.state"/>

                <!--                <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.state.$dirty">-->
                <!--                  <div class="w-full">-->
                <!--                    <vs-alert :active="!$v.currentEventAddress.toAddress.state.required" :color="'danger'">-->
                <!--                      Polje "Občina" ne sme biti prazno.-->
                <!--                    </vs-alert>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div class="w-1/4 ml-5">
                <vs-input class="w-full" label="Država" v-model="currentEventAddress.toAddress.country"/>

                <!--                <div class="flex mt-1" v-if="$v.currentEventAddress.toAddress.country.$dirty">-->
                <!--                  <div class="w-full">-->
                <!--                    <vs-alert :active="!$v.currentEventAddress.toAddress.country.required" :color="'danger'">-->
                <!--                      Polje "Država" ne sme biti prazno.-->
                <!--                    </vs-alert>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="grid grid-cols-1 mt-5">
        <div>
          <vs-button size="medium" color="success" icon="check" @click="addEvent()">
            <span v-if="currentEventId !== null">Shrani spremembe</span>
            <span v-if="currentEventId === null">Dodaj dogodek</span>
          </vs-button>
        </div>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import Vue from 'vue';
import vSelect from "vue-select";
import generateUniqueId from "generate-unique-id";
import {VueEditor} from "vue2-editor";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/sl';

export default {
  name: 'PhoneBookEdit',

  components: {
    'v-select': vSelect,
    'vue-editor': VueEditor,
    DatePicker,
  },

  data() {
    return {
      entry: {
        "title": "",
        "body": "",
        "formattedBody": "",
        "type": "GENERAL",
        "eventDetails": "",
        "linkDetails": "",
        "image": "",
        "pushNotification": true,
        "customerIds": [
          "string"
        ]
      },
      editEventModal: false,
      currentEventIdx: null,
      currentEventId: null,
      currentEventTitle: null,
      currentEventAddress: {
        "toAddress": {
          "quick_search": null,
          "addressName": null,
          "line1": null,
          "line2": null,
          "postal_code": null,
          "city": null,
          "state": null,
          "country": null
        },
        "notes": null,
        "oneWayTransportation": false
      },
      currentEventStartTime: null,
      currentEventEndTime: null,

      editLinkModal: false,
      currentLinkIdx: null,
      currentLinkId: null,
      currentLinkLabel: null,
      currentLinkURL: null,
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    if (_this.$route.params.notificationId) {
      _this.getEntry();
    } else {

    }
  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    next();
  },

  methods: {
    async getEntry() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'notifications/' + _this.$route.params.notificationId)
        .then((res) => {
          _this.entry = res.data.data;
          _this.entry.eventDetails = JSON.parse(res.data.data.eventDetails);
          _this.entry.linkDetails = JSON.parse(res.data.data.linkDetails);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    showAddEventModal() {
      const _this = this;

      _this.currentEventId = null;
      _this.currentEventIdx = null;
      _this.currentEventIdx = null;
      _this.currentEventId = null;
      _this.currentEventTitle = null;
      _this.currentEventAddress = {
        "toAddress": {
          "quick_search": null,
          "addressName": null,
          "line1": null,
          "line2": null,
          "postal_code": null,
          "city": null,
          "state": null,
          "country": null
        },
        "notes": null,
        "oneWayTransportation": false,
      };
      _this.currentEventStartTime = null;
      _this.currentEventEndTime = null;
      _this.editEventModal = true;
    },

    editEvent(item, idx) {
      const _this = this;

      console.log("edit item", item, "idx", idx);

      _this.currentEventId = item.id;
      _this.currentEventIdx = idx;

      _this.currentEventTitle = item.eventTitle;
      _this.currentEventAddress = {
        "toAddress": item.reservationData.toAddress,
        "notes": item.reservationData.notes,
        "oneWayTransportation": item.reservationData.oneWayTransportation,
      };
      _this.currentEventStartTime = item.eventStartTime;
      _this.currentEventEndTime = item.eventEndTime;

      _this.editEventModal = true;
    },

    deleteEvent(item, idx) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati povezavo z oznako: ' + item.label + '?',
        message: 'Po potrditvi bo vnos izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.entry.linkDetails.splice(idx, 1);
          }
        }
      });
    },

    addEvent() {
      const _this = this;

      console.log("_this.currentEventId", _this.currentEventId);

      if (_this.currentEventId) {
        _this.entry.eventDetails[_this.currentEventIdx] = {
          id: _this.currentEventId,
          eventTitle: _this.currentEventTitle,
          eventStartTime: _this.currentEventStartTime,
          eventEndTime: _this.currentEventEndTime,
          reservationData:_this.currentEventAddress
        };
      } else {
        _this.entry.eventDetails = [];
        _this.entry.eventDetails.push({
          id: generateUniqueId(),
          eventTitle: _this.currentEventTitle,
          eventStartTime: _this.currentEventStartTime,
          eventEndTime: _this.currentEventEndTime,
          reservationData:_this.currentEventAddress
        });
      }
      _this.editEventModal = false;
    },

    showAddLink() {
      const _this = this;

      _this.currentLinkId = null;
      _this.currentLinkIdx = null;
      _this.currentLinkURL = null;
      _this.currentLinkLabel = null;
      _this.editLinkModal = true;
    },

    editLink(item, idx) {
      const _this = this;

      console.log("edit item", item, "idx", idx);

      _this.currentLinkId = item.id;
      _this.currentLinkIdx = idx;
      _this.currentLinkURL = item.link;
      _this.currentLinkLabel = item.label;
      _this.editLinkModal = true;
    },

    deleteLink(item, idx) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati povezavo z oznako: ' + item.label + '?',
        message: 'Po potrditvi bo vnos izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.entry.linkDetails.splice(idx, 1);
          }
        }
      });


    },

    addLink() {
      const _this = this;

      if (_this.currentLinkId) {
        _this.entry.linkDetails[_this.currentLinkIdx] = {
          id: _this.currentLinkId,
          link: _this.currentLinkURL,
          label: _this.currentLinkLabel,
        };
      } else {
        _this.entry.linkDetails = [];
        _this.entry.linkDetails.push({
          id: generateUniqueId(),
          link: _this.currentLinkURL,
          label: _this.currentLinkLabel,
        });
      }
      _this.editLinkModal = false;
    },

    getAddressData(addressData, addressSection) {
      const _this = this;

      console.log("addressData ---->", addressData);
      console.log("addressSection", addressSection);

      console.log("_this.reservationData BEFORE: ", _this.reservationData);
      document.getElementById(addressSection + "Address").value = addressData.formatted_address;
      _this.currentEventAddress.toAddress.quick_search = addressData.formatted_address;
      _this.currentEventAddress.toAddress.line1 = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'route');
      _this.currentEventAddress.toAddress.line2 = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'street_number');
      _this.currentEventAddress.toAddress.city = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'postal_town');
      _this.currentEventAddress.toAddress.postal_code = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'postal_code');
      _this.currentEventAddress.toAddress.state = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'administrative_area_level_1') ? Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'administrative_area_level_1') : Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'postal_town');
      _this.currentEventAddress.toAddress.country = Vue.prototype.$globalFunctions.parseGeocompleteData(addressData, 'country');

      _this.$forceUpdate();
    },


    fileChanged() {
      const _this = this;

      _this.$vs.loading();

      let file = _this.$refs.file;
      console.log(file.files[0]);

      let formData = new FormData();
      formData.append('files', file.files[0]);

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'volunteersImageUpload', formData)
        .then((res) => {

          console.log("RES!!!!!", res)
          _this.volunteersData.profile_image = res.data.data.fileId;

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },


    createPushNotification() {
      const _this = this;

      _this.$vs.loading();

      let entry = {
        "title": _this.entry.title,
        "body": _this.entry.body,
        "formattedBody": _this.entry.formattedBody,
        "type": _this.entry.type ? _this.entry.type : 'GENERAL',
        "eventDetails": _this.entry.eventDetails ? JSON.stringify(_this.entry.eventDetails) : null,
        "linkDetails": _this.entry.linkDetails ? JSON.stringify(_this.entry.linkDetails) : null,
        "image": _this.entry.image,
        "pushNotification": true
      };

      Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'notifications', entry)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$router.push({name: 'Notifications'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    updatePushNotification() {
      const _this = this;

      _this.$vs.loading();

      let entry = {
        "title": _this.entry.title,
        "body": _this.entry.body,
        "formattedBody": _this.entry.formattedBody,
        "type": _this.entry.type ? _this.entry.type : 'GENERAL',
        "eventDetails": _this.entry.eventDetails ? JSON.stringify(_this.entry.eventDetails) : null,
        "linkDetails": _this.entry.linkDetails ? JSON.stringify(_this.entry.linkDetails) : null,
        "image": _this.entry.image,
        "pushNotification": true
      };

      Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'notifications/' + _this.$route.params.notificationId, entry)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$router.push({name: 'Notifications'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

  }
}
</script>

<style>
.pac-container.pac-logo {
  z-index: 99999999;
}
</style>

